import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Cassette from "../components/cassette"
import { EntriesIndexQuery } from '../../types/graphql-types'

interface IEntriesIndexProps {
  data: EntriesIndexQuery;
  location: Location;
}

const EntriesIndex: React.FC<IEntriesIndexProps>  = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      {posts.map(({ node }) => {
        return (
          <Cassette node={node} link={node.fields.slug.substr(1)} />
        )
      })}
    <Bio />
    </Layout>
  )
}

export default EntriesIndex

export const pageQuery = graphql`
  query EntriesIndex {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(truncate: true, pruneLength: 100)
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            hero
          }
        }
      }
    }
  }
`
